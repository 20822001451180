import Vue from 'vue'
import Vuex from 'vuex'
import {getUinfo} from "../apis/index"
import {getTimes} from "../utils/index"
import router from "../router"
import { MessageBox } from "element-ui"
Vue.use(Vuex)
export default new Vuex.Store({
  namespaced:true,
  state: {
    indexDialog:false,
    show:true,
    uinfo:{},
    sms_number:'',
    templateType:[],
    collapse:false,
    requestUrl:"https://kspjapi.niuniucrm.com/"
  },
  getters: {
  },
  mutations: {
    // 保存用户信息
    info(state,uinfo){
      state.uinfo=uinfo
      state.sms_number=uinfo.sms_number
      localStorage.setItem("uinfo",JSON.stringify(uinfo))
    },
    changeShow(state){
      state.show=!state.show
    },
    // 是否隐藏左边导航
    changeCollapse(state,show){
      state.collapse=show
    },
    hidDialog(state,bool){
      state.indexDialog = bool
    }
  },
  actions: {
    // 获取用户信息
    async getUinfo(context){
      await getUinfo().then(res=>{
        let enddate = res.data.shop_end_date;
        res.data.shop_end_date = getTimes(enddate);
        let date = Math.ceil(
          (enddate*1000 - (new Date()).getTime()) / 1000 / 60 / 60 / 24
        )
        res.data.surplusday = date<1?0:date;
        res.data.kf_image=this.state.requestUrl+res.data.kf_image
        if(!res.data.shop_mobile){
          context.commit('hidDialog',true)
        }else{
          context.dispatch("showLabelTips")
        }
        context.commit("info",res.data)
      }).catch(err=>{})
    },
    // 显示短信签名提示
    showLabelTips(context){
      if(context.state.uinfo.isReadImportantSend !== 1){
        MessageBox({
          title: '温馨提示',
          showCancelButton: false,
          confirmButtonText: "前往签名报备",
          message: "<div>最新通知！由于运营商政策管控，发送短信前需要进行签名报备。请您先到<strong style='color: red'>个人中心-短信签名</strong>，填写相关资料。如有疑问，请及时联系对接商务或在线客服，感谢您的理解与配合!</div>",
          dangerouslyUseHTMLString: true,
          callback: action => {
            if(action === "confirm"){
              sessionStorage.setItem("showAdd", true)
              router.push("/member/labelList")
            }
          }
        })
      }
    },
  },
  modules: {
  }
})
